import { IconButton } from "@material-ui/core";
import { IAmpIconButtonProps } from "./types/AmpIconButtonTypes";
import * as Icons from "@material-ui/icons";

export const AmpIconButton = ({
	disabled = false,
	icon: iconName,
	...props
}: IAmpIconButtonProps) => {
	const IconComponent = Icons[iconName];

	return (
		<IconButton {...props} disabled={disabled}>
			<IconComponent/>
		</IconButton>
	);
};
