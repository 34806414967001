import { Link } from "@material-ui/core";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { useBrandingContext } from "src/contexts/BrandingContext";
import { useGetOneLinkUrl } from "src/hooks/useGetOneLinkUrl";

export const DownloadAppCard = () => {
	const { oneLinkUrl } = useGetOneLinkUrl();
	const {
		configurableTextsKeyed: {
			KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_PROMPT_HEADER,
			KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_PROMPT_SUB_HEADER,
			KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_BUTTON,
		},
	} = useBrandingContext();

	return (
		<AmpContainer
			flex={1}
			alignItems="center"
			width="full"
			justifyContent="center"
			gap={10}
		>
			<AmpContainer
				gap={2}
				width="full"
				alignItems="center"
				justifyContent="center"
			>
				<AmpText type="body_lg" fontWeight="bold" align="center">
					{KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_PROMPT_HEADER ??
						"Haven't Downloaded the App Yet?"}
				</AmpText>
				<AmpText type="body_sm" color="gray" align="center">
					{KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_PROMPT_SUB_HEADER ??
						"You can download the mobile app by clicking the button below:"}
				</AmpText>
			</AmpContainer>
			<Link target="_blank" href={oneLinkUrl} rel="noreferrer">
				<AmpButton
					label={
						KIOSK_PURCHASE_MEMBERSHIP_APP_DOWNLOAD_BUTTON ??
						"Click To Download the App"
					}
					colorType="secondary"
				/>
			</Link>
		</AmpContainer>
	);
};
