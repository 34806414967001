import { Grid } from "@material-ui/core";
import { useMembershipPurchaseContext } from "../../contexts/MembershipPurchaseContext";
import { CustomerInfoForm } from "./components/CustomerInfoForm";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { useMemo } from "react";
import { useBrandingContext } from "src/contexts/BrandingContext";

export const CustomerInfoScreen = () => {
	const { product, selectedPriceId } = useMembershipPurchaseContext();
	const priceInDollars = useMemo(() => {
		const price = product?.prices?.find((price) => price.id === selectedPriceId)
			?.metadata.Tiers[0].AmountPerUnit;
		if (price) {
			return "$" + price / 100;
		}
		return undefined;
	}, [product, selectedPriceId]);

	const {
		info: brandingInfo,
		configurableTextsKeyed: {
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_USER_INFO_HEADER,
		},
	} = useBrandingContext();

	const headerText =
		KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_USER_INFO_HEADER?.replace(
			"[BusinessName]",
			brandingInfo?.name || "",
		) ?? "Please provide some information to create your account";

	return (
		<>
			<AmpContainer
				padding={4}
				alignItems="center"
				width="full"
				color="surface"
			>
				<Grid
					container
					item
					direction="column"
					spacing={3}
					md={6}
					sm={12}
					justifyContent="center"
				>
					<Grid item>
						<AmpCard padding={6}>
							<AmpText type="body_lg" fontWeight="bold">
								{headerText}
							</AmpText>
						</AmpCard>
					</Grid>
					<Grid item>
						<AmpCard padding={6} gap={6} flexDirection="column">
							<AmpText type="body_lg" fontWeight="bold">
								Subtotal
							</AmpText>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<AmpText type="body_sm">{product?.name}</AmpText>
								<AmpText type="body_sm">{priceInDollars}</AmpText>
							</Grid>
							<AmpDivider />
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<AmpText type="body_sm" fontWeight="bold">
									Amount Due Today
								</AmpText>
								<AmpText type="body_sm" fontWeight="bold">
									{priceInDollars}
								</AmpText>
							</Grid>
						</AmpCard>
					</Grid>
				</Grid>
			</AmpContainer>
			<CustomerInfoForm />
		</>
	);
};
