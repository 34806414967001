import { Grid, MenuItem } from "@material-ui/core";
import { EAccountApproach, formatPhoneNumber, phoneRegExp } from "amp";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpCheckbox } from "src/components/ui-core/inputs/AmpCheckbox";
import { AmpTextInput } from "src/components/ui-core/inputs/AmpTextInput";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpSpacer } from "src/components/ui-core/spacers/AmpSpacer";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { useModalContext } from "src/contexts/ModalContext";
import {
	EMembershipPurchaseScreen,
	useMembershipPurchaseContext,
} from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";
import { useMembershipPurchaseUser } from "src/sites/membership-purchase/hooks/useCreateUser";
import { useGetLicensePlateDataFromKioskSession } from "src/sites/membership-purchase/hooks/useGetLicensePlateDataFromKioskSession";
import { useTermsAndHelpLink } from "src/sites/membership-purchase/hooks/useTermsAndHelpLink";
import * as Yup from "yup";
import { states } from "../data/states";
import { useGetAccountApproach } from "src/hooks/useGetAccountApproach";

export interface ICustomerInfoForm {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	licensePlateNumber: string;
	licensePlateState: string;
	receiveAccountTexts: boolean;
	receivePromotionTexts: boolean;
}

export const CustomerInfoForm = () => {
	const {
		initialCustomerPhoneNumber,
		userData,
		setUserData,
		setScreen,
		prices,
		kiosk: { id: kioskId },
	} = useMembershipPurchaseContext();
	const { licensePlateData } = useGetLicensePlateDataFromKioskSession(kioskId);
	const { createUser, updateUser } = useMembershipPurchaseUser();
	const { accountApproach } = useGetAccountApproach();
	// Get links
	const [smsTermsAndHelpLink] = useTermsAndHelpLink("SMS_TERMS_OF_SERVICE");
	const [privacyTermsAndHelpLink] = useTermsAndHelpLink("PRIVACY_STATEMENT");
	const { showError } = useModalContext();

	const multiplePricesAvailable = useMemo(() => {
		return (prices?.length ?? 0) > 1;
	}, [prices]);

	const initialFormValues = useMemo(
		() => ({
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: initialCustomerPhoneNumber,
			licensePlateNumber: "",
			licensePlateState: "",
			receiveAccountTexts: false,
			receivePromotionTexts: false,
		}),
		[initialCustomerPhoneNumber],
	);

	const chargePerVehicle =
		accountApproach === EAccountApproach.ChargePerVehicleMulti ||
		accountApproach === EAccountApproach.ChargePerVehicleSingle;

	const validationSchema = useMemo(
		() =>
			Yup.object({
				firstName: Yup.string().required("First name is required"),
				lastName: Yup.string().required("Last name is required"),
				email: Yup.string()
					.email("Please enter a valid email address")
					.required("Email is required"),
				phoneNumber: Yup.string()
					.required("Phone number is required")
					.matches(phoneRegExp, "Phone number must be 10 numbers"),
				licensePlateNumber: Yup.string().when({
					is: () => chargePerVehicle,
					then: Yup.string().required("License plate number is required"),
				}),
				licensePlateState: Yup.string().when({
					is: () => chargePerVehicle,
					then: Yup.string().required("License plate state is required"),
				}),
				receiveAccountTexts: Yup.boolean().required(),
				receivePromotionTexts: Yup.boolean().required(),
			}),
		[chargePerVehicle],
	);

	const handleSubmit = useCallback(
		async (
			{
				phoneNumber,
				firstName,
				lastName,
				email,
				licensePlateNumber,
				licensePlateState,
				receiveAccountTexts,
				receivePromotionTexts,
			}: ICustomerInfoForm,
			{ setErrors }: FormikHelpers<ICustomerInfoForm>,
		) => {
			try {
				let ampContext;
				if (userData) {
					ampContext = await updateUser({
						userId: userData.userId,
						email,
						name: `${firstName} ${lastName}`,
						receiveAccountTexts: receiveAccountTexts,
						receivePromotionTexts: receivePromotionTexts,
						licensePlateNumber: licensePlateNumber ?? undefined,
						licensePlateState: licensePlateState ?? undefined,
					});
				} else {
					ampContext = await createUser({
						email,
						name: `${firstName} ${lastName}`,
						phoneNumber: phoneNumber,
						receiveAccountTexts: receiveAccountTexts,
						receivePromotionTexts: receivePromotionTexts,
						licensePlateNumber: licensePlateNumber ?? undefined,
						licensePlateState: licensePlateState ?? undefined,
					});
				}
				setUserData({
					userId: ampContext.mobileUser.external_id__c,
					accountId: ampContext.accountContext.account.external_id__c,
					stripeCustomerId: ampContext.accountContext.stripeCustomerId,
					vehicle:
						accountApproach === EAccountApproach.ChargePerUser
							? undefined
							: {
									id: ampContext.accountContext.accountVehicles?.[0]?.vehicleId,
									licensePlateNumber:
										ampContext.accountContext.accountVehicles?.[0]?.vehicle
											?.license_plate_number ?? "",
									licensePlateState:
										ampContext.accountContext.accountVehicles?.[0]?.vehicle
											?.license_plate_state ?? "",
							  },
					name: ampContext.mobileUser.name ?? undefined,
					email: ampContext.mobileUser.email__c,
					phoneNumber: ampContext.mobileUser.phone_number__c ?? "",
				});
				setScreen(EMembershipPurchaseScreen.Payment);
			} catch (error) {
				const headerText = userData
					? "Account Update Error"
					: "Account Sign Up Error";
				const message = `There was an error ${
					userData ? "updating" : "creating"
				} your account. Please try again or contact an admin for support.`;
				showError({
					headerText,
					message,
				});
			}
		},
		[createUser, updateUser, setUserData, setScreen, userData, showError],
	);

	const formik = useFormik({
		initialValues: initialFormValues,
		validateOnMount: true,
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema,
		onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
	});

	const {
		setFieldTouched,
		setFieldValue,
		handleChange,
		errors,
		values,
		touched,
		isValid,
		isSubmitting,
		validateForm,
	} = formik;

	useEffect(() => {
		if (licensePlateData?.licensePlateNumber) {
			setFieldValue("licensePlateNumber", licensePlateData.licensePlateNumber);
		}
		if (licensePlateData?.licensePlateState) {
			setFieldValue("licensePlateState", licensePlateData.licensePlateState);
		}
	}, [licensePlateData, setFieldValue]);

	useEffect(() => {
		if (userData) {
			// Set all fields
			const promises = [
				setFieldValue("email", userData.email ?? ""),
				setFieldValue("firstName", userData.name?.split(" ")[0] ?? ""),
				setFieldValue("lastName", userData.name?.split(" ")[1] ?? ""),
				setFieldValue(
					"licensePlateNumber",
					userData.vehicle?.licensePlateNumber ?? "",
				),
				setFieldValue(
					"licensePlateState",
					userData.vehicle?.licensePlateState ?? "",
				),
			];
			// After all fields are set, trigger validation
			Promise.all(promises).then(() => {
				// Mark fields as touched
				setFieldTouched("email", true, false);
				setFieldTouched("firstName", true, false);
				setFieldTouched("lastName", true, false);
				if (chargePerVehicle) {
					setFieldTouched("licensePlateNumber", true, false);
					setFieldTouched("licensePlateState", true, false);
				}

				// Validate all fields
				validateForm();
			});
		}
	}, [
		userData,
		setFieldValue,
		setFieldTouched,
		validateForm,
		chargePerVehicle,
	]);

	const onContinueClick = useCallback(() => {
		formik.submitForm();
	}, [formik]);

	return (
		<>
			<AmpContainer width="full" color="surface" alignItems="center">
				<Grid
					container
					item
					direction="column"
					md={6}
					sm={12}
					justifyContent="center"
				>
					<AmpContainer width="full" padding={4} alignItems="center">
						<AmpCard padding={6}>
							<Grid container item direction="column" spacing={2}>
								<Grid item>
									<AmpText
										type="body_lg"
										fontWeight="bold"
										textTransform="capitalize"
									>
										Your Information
									</AmpText>
									<AmpSpacer height={2} />
								</Grid>
								<Grid item>
									<AmpTextInput
										fullWidth
										size="small"
										variant="outlined"
										name="firstName"
										type="text"
										label="First Name"
										value={values.firstName}
										onChange={(e) => {
											setFieldTouched("firstName");
											handleChange(e);
										}}
										error={
											Boolean(touched.firstName) && Boolean(errors.firstName)
										}
										helperText={Boolean(touched.firstName) && errors.firstName}
									/>
								</Grid>
								<Grid item>
									<AmpTextInput
										fullWidth
										size="small"
										variant="outlined"
										name="lastName"
										type="text"
										label="Last Name"
										value={values.lastName}
										onChange={(e) => {
											setFieldTouched("lastName");
											handleChange(e);
										}}
										error={
											Boolean(touched.lastName) && Boolean(errors.lastName)
										}
										helperText={Boolean(touched.lastName) && errors.lastName}
									/>
								</Grid>
								<Grid item>
									<AmpTextInput
										fullWidth
										size="small"
										variant="outlined"
										name="email"
										type="email"
										label="Email"
										value={values.email}
										onChange={(e) => {
											setFieldTouched("email");
											handleChange(e);
										}}
										error={Boolean(errors.email) && Boolean(touched.email)}
										helperText={Boolean(touched.email) && errors.email}
									/>
								</Grid>
								<Grid item>
									<AmpTextInput
										size="small"
										variant="outlined"
										name="phoneNumber"
										label="Phone Number"
										disabled={Boolean(userData)}
										value={formatPhoneNumber(values.phoneNumber)}
										onChange={(e) => {
											setFieldTouched("phoneNumber");
											handleChange(e);
										}}
										error={
											Boolean(errors.phoneNumber) &&
											Boolean(touched.phoneNumber)
										}
										helperText={
											touched.phoneNumber ? errors.phoneNumber : undefined
										}
									/>
								</Grid>
								{chargePerVehicle && (
									<Grid item>
										<AmpTextInput
											fullWidth
											size="small"
											variant="outlined"
											name="licensePlateNumber"
											type="text"
											label="License Plate Number"
											value={values.licensePlateNumber.toUpperCase()}
											onChange={(e) => {
												setFieldTouched("licensePlateNumber");
												handleChange(e);
											}}
											error={
												Boolean(errors.licensePlateNumber) &&
												Boolean(touched.licensePlateNumber)
											}
											helperText={
												Boolean(touched.licensePlateNumber) &&
												errors.licensePlateNumber
											}
										/>
									</Grid>
								)}

								{chargePerVehicle && (
									<Grid item>
										<AmpTextInput
											select
											size="small"
											variant="outlined"
											name="licensePlateState"
											type="text"
											label="License Plate State"
											value={values.licensePlateState}
											onChange={(e) => {
												setFieldTouched("licensePlateState");
												handleChange(e);
											}}
											error={
												Boolean(errors.licensePlateState) &&
												Boolean(touched.licensePlateState)
											}
											helperText={
												Boolean(touched.licensePlateState) &&
												errors.licensePlateState
											}
										>
											{states.map((state) => (
												<MenuItem key={state.value} value={state.value}>
													{state.label}
												</MenuItem>
											))}
										</AmpTextInput>
									</Grid>
								)}
								<AmpSpacer height={6} />
								<Grid item>
									<AmpCheckbox
										checked={values.receiveAccountTexts}
										onChange={handleChange}
										name="receiveAccountTexts"
										color="secondary"
										label="Receive account texts"
									/>
									<AmpCheckbox
										checked={values.receivePromotionTexts}
										onChange={handleChange}
										name="receivePromotionTexts"
										color="secondary"
										label="Receive promotion texts"
									/>
								</Grid>
								<AmpSpacer height={6} />
								<Grid item>
									<AmpText type="body_sm">Message frequency varies.</AmpText>
									<AmpText type="body_sm">
										Message and data rates may apply.{" "}
									</AmpText>
									<AmpSpacer height={4} />
									<AmpText type="body_sm">
										Reply HELP for help or STOP to cancel.
									</AmpText>
									<AmpSpacer height={4} />
									<AmpText type="body_sm" color="primary">
										<a
											href={smsTermsAndHelpLink?.url__c}
											target="_blank"
											rel="noreferrer"
										>
											SMS Terms of Service
										</a>{" "}
										|{" "}
										<a
											href={privacyTermsAndHelpLink?.url__c}
											target="_blank"
											rel="noreferrer"
										>
											Privacy Statement
										</a>
									</AmpText>
								</Grid>
							</Grid>
						</AmpCard>
						<AmpSpacer height={4} />
					</AmpContainer>
				</Grid>
				<AmpContainer
					color="white"
					width="full"
					position="sticky"
					positionLocationSpacing={{ location: "bottom", spacing: 0 }}
				>
					<AmpDivider />
					<AmpContainer
						width="full"
						color="white"
						padding={4}
						gap={2}
						alignItems="center"
					>
						<Grid container item direction="row" spacing={2} md={6} sm={12}>
							{multiplePricesAvailable && (
								<Grid item xs={4}>
									<AmpButton
										label="Back"
										colorType="primary"
										type="outlined"
										onClick={() =>
											setScreen(EMembershipPurchaseScreen.PriceLevel)
										}
										width="wide"
									/>
								</Grid>
							)}
							<Grid item xs={multiplePricesAvailable ? 8 : 12}>
								<AmpButton
									onClick={onContinueClick}
									disabled={!isValid || isSubmitting}
									label="Continue"
									width="wide"
									colorType="secondary"
									type="filled"
									loading={isSubmitting}
									size="md"
								/>
							</Grid>
						</Grid>
					</AmpContainer>
				</AmpContainer>
			</AmpContainer>
		</>
	);
};
