import {
	EEndpointType,
	ESubscriptionSource,
	IAnonymousValidateNewSubscriptionRequest,
	XValidatePromoCodeResult,
} from "amp";
import { useCallback } from "react";
import { useApi } from "src/hooks/useApi";
import { useMembershipPurchaseContext } from "src/sites/membership-purchase/contexts/MembershipPurchaseContext";

export const useValidatePromoCode = () => {
	const { post } = useApi(EEndpointType.Anonymous, "promo-code");
	const {
		kiosk: { location_id },
        userData
	} = useMembershipPurchaseContext();

	const valiadatePromoCode = useCallback(
		(
			promoCode: string,
			userId: string,
			userPhoneNumber: string,
			subTotal: number,
			priceId: number,
		) =>
			post<IAnonymousValidateNewSubscriptionRequest, XValidatePromoCodeResult>(
				"validate-new-subscription",
				{
					codeName: promoCode,
					userId,
					phoneNumber: userPhoneNumber,
					subtotal: subTotal,
					locationId: location_id,
					vehicleId: userData?.vehicle?.id ?? null,
					source: ESubscriptionSource.New,
					priceId,
				},
			),
		[post, location_id, userData],
	);

    return valiadatePromoCode;
};
