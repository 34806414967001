const environment = {
	api: {
		url: "https://api-sandbox.ampmemberships.com",
	},
	localhost: {
		tenantKey: process.env.LOCALHOST_TENANTKEY??"",
	},
	aws: {
		url: "https://sandbox-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	appInsights: {
		instrumentationKey: "36f8c2f4-0140-4a26-a0b1-3089fa4315a1" ,
	},
	sentry: {
		dsn: "https://0b50e99820944ffa9367fed61f9e7f6e@o541552.ingest.sentry.io/5670659",
	},
	mapbox: {
		apiKey: "pk.eyJ1IjoiaW5zaXRlbnhhbXAiLCJhIjoiY2w2NnZpYXB6MDM0bjNjcno2cjJ2a2o5ZyJ9.FhL2-3SmRc25dEQD7Qc4_Q" ,
	},
};

export default environment;
