import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpSuccessCheckmark } from "src/components/ui-core/svgs/AmpSuccessCheckmark";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { Grid } from "@material-ui/core";
import { DownloadAppCard } from "../../components/DownloadAppCard";
import { useBrandingContext } from "src/contexts/BrandingContext";
import { useApi } from "src/hooks/useApi";
import {
	EEndpointType,
	IStartWashAtKioskPostMembershipCreationRequest,
} from "amp";
import { useEffect, useState } from "react";
import { useModalContext } from "src/contexts/ModalContext";
import { useMembershipPurchaseContext } from "../../contexts/MembershipPurchaseContext";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";

const oneMinute = 60000;

export const PaymentSuccessScreen = () => {
	const [userDeniedWash, setUserDeniedWash] = useState(false);
	const {
		configurableTextsKeyed: {
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCESS_HEADER,
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCCESS_SUB_HEADER_WASH_ACCEPTED,
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCCESS_SUB_HEADER_WASH_DENIED,
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_CONFIRM_START_WASH_HEADER,
			KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_CONFIRM_START_WASH_SUB_HEADER,
		},
	} = useBrandingContext();

	const { post } = useApi(EEndpointType.Anonymous, "kiosk");
	const { showError, customModal, close } = useModalContext();
	const {
		kiosk: { id: kioskId, tenant__r__external_id__c: tenantId },
		userData,
	} = useMembershipPurchaseContext();

	const startWashAtKiosk = async () => {
		try {
			if (userData?.accountId && userData?.userId)
				await post<IStartWashAtKioskPostMembershipCreationRequest, void>(
					"start-member-wash",
					{
						TenantId: tenantId,
						AccountId: userData.accountId,
						KioskId: kioskId,
						UserId: userData.userId,
						VehicleId: userData?.vehicle?.id,
					},
				);
		} catch {
			showError({
				headerText: "Failed to start wash at kiosk",
				message:
					"Please download the app to get a wash or contact an admin for support",
			});
		}
	};

	const onStartWashConfirm = () => {
		startWashAtKiosk();
		close();
	};

	const onStartWashDeny = () => {
		setUserDeniedWash(true);
		close();
	};

	useEffect(() => {
		setTimeout(
			() =>
				customModal({
					modalContent: (
						<ConfirmStartWashModal
							headerText={
								KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_CONFIRM_START_WASH_HEADER ??
								"Ready to use your first wash?"
							}
							subHeaderText={
								KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_CONFIRM_START_WASH_SUB_HEADER ??
								"Please confirm that you are still at the kiosk"
							}
							onDeny={onStartWashDeny}
							onConfirm={onStartWashConfirm}
						/>
					),
					onClose: () => {},
					showCloseIcon: false,
				}),
			750,
		);
		setTimeout(() => close(), oneMinute);
	}, []);

	return (
		<AmpContainer
			padding={4}
			alignItems="center"
			width="full"
			flexGrow={1}
			color="surface"
		>
			<Grid
				container
				item
				direction="column"
				md={6}
				sm={12}
				justifyContent="center"
				style={{ height: "100%" }}
			>
				<AmpCard padding={6} fullWidth alignItems="center" gap={6} fullHeight>
					<AmpContainer
						flex={1}
						alignItems="center"
						width="full"
						justifyContent="center"
						gap={4}
					>
						<AmpSuccessCheckmark />
						<AmpContainer
							width="full"
							alignItems="center"
							justifyContent="center"
						>
							<AmpText type="body_lg" fontWeight="bold" align="center">
								{KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCESS_HEADER ??
									"Congratulations!"}
							</AmpText>
							<AmpText type="body_sm" color="gray" align="center">
								{userDeniedWash
									? KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCCESS_SUB_HEADER_WASH_DENIED ??
									  "Please download the app to start your first wash"
									: KIOSK_PURCHASE_MEMBERSHIP_WEB_APP_SUCCESS_SUB_HEADER_WASH_ACCEPTED ??
									  "Please proceed at the kiosk"}
							</AmpText>
						</AmpContainer>
					</AmpContainer>
					<AmpDivider />
					<DownloadAppCard />
				</AmpCard>
			</Grid>
		</AmpContainer>
	);
};

const ConfirmStartWashModal = ({
	headerText,
	subHeaderText,
	onDeny,
	onConfirm,
}: {
	headerText: string;
	subHeaderText: string;
	onDeny: () => void;
	onConfirm: () => void;
}) => (
	<AmpContainer width="full" gap={10} alignItems="center" padding={6}>
		<AmpContainer gap={2} width="full" alignItems="center">
			<AmpText type="body_lg" fontWeight="bold" align="center">
				{headerText}
			</AmpText>
			<AmpText type="body_md" color="gray" align="center">
				{subHeaderText}
			</AmpText>
		</AmpContainer>
		<AmpContainer direction="row" gap={4} alignItems="center" width="full">
			<AmpContainer alignItems="center" justifyContent="center" width="full">
				<AmpButton
					label="No"
					colorType="primary"
					type="outlined"
					width="wide"
					onClick={onDeny}
				/>
			</AmpContainer>
			<AmpContainer width="full" alignItems="center" justifyContent="center">
				<AmpButton
					label="Yes"
					colorType="secondary"
					width="wide"
					onClick={onConfirm}
				/>
			</AmpContainer>
		</AmpContainer>
	</AmpContainer>
);
