import { Grid } from "@material-ui/core";
import { useState } from "react";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpDivider } from "src/components/ui-core/spacers/AmpDivider";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import {
	EMembershipPurchaseScreen,
	useMembershipPurchaseContext,
} from "../../contexts/MembershipPurchaseContext";
import { AmpRadioCard } from "src/components/ui-core/inputs/AmpRadioCard";
import { toDollars } from "src/utils/toDollars";
import { EIntervalType, IProductPriceWithJson } from "amp";

export const SelectPriceLevelScreen = () => {
	const { setSelectedPriceId, setScreen, selectedPriceId, prices, product } =
		useMembershipPurchaseContext();
	const [priceId, setPriceId] = useState<number | undefined>(selectedPriceId);

	return (
		<>
			<AmpContainer height="full" width="full">
				<AmpContainer
					width="full"
					flexGrow={1}
					color="surface"
					alignItems="center"
					padding={4}
				>
					<Grid container direction="row" md={6} sm={12}>
						<AmpContainer gap={6} width="full" alignItems="center">
							<AmpText type="body_lg" fontWeight="bold" align="center">
								{`How would you like to pay for your ${product?.name} membership?`}
							</AmpText>
							<AmpContainer gap={4} width="full">
								{prices?.map((price) => (
									<PriceCard
										price={price}
										onClick={(priceId: number) => setPriceId(priceId)}
										selectedPriceId={priceId}
									/>
								))}
							</AmpContainer>
						</AmpContainer>
					</Grid>
				</AmpContainer>
				<AmpContainer
					width="full"
					position="sticky"
					positionLocationSpacing={{ location: "bottom", spacing: 0 }}
					color="white"
				>
					<AmpDivider />
					<AmpContainer padding={4} width="full" alignItems="center">
						<Grid container direction="row" md={6} sm={12}>
							<AmpButton
								onClick={() => {
									if (!priceId) return;
									setSelectedPriceId(priceId);
									setScreen(EMembershipPurchaseScreen.Info);
								}}
								label="Continue"
								width="wide"
								colorType="secondary"
								disabled={!priceId}
							/>
						</Grid>
					</AmpContainer>
				</AmpContainer>
			</AmpContainer>
		</>
	);
};

const PriceCard = ({
	price,
	onClick,
	selectedPriceId,
}: {
	price: IProductPriceWithJson;
	onClick: (priceId: number) => void;
	selectedPriceId?: number;
}) => {
	const priceText = toDollars(price.metadata.Tiers[0].AmountPerUnit);

	const intervalText =
		price.interval_count > 1
			? getIntervalText(price.interval_type) + "s"
			: getIntervalText(price.interval_type);

	const frequency =
		"Every " +
		`${price.interval_count > 1 ? `${price.interval_count} ` : ""}` +
		intervalText;

	return (
		<AmpRadioCard
			onClick={() => {
				onClick(price.id);
			}}
			checked={price.id === selectedPriceId}
		>
			<AmpContainer
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				width="full"
				flex={1}
			>
				<AmpText type="body_md" fontWeight="bold">
					{frequency}
				</AmpText>
				<AmpText type="h5" fontWeight="bold" color="primary">
					{priceText}
				</AmpText>
			</AmpContainer>
		</AmpRadioCard>
	);
};

const getIntervalText = (intervalType: EIntervalType) => {
	switch (intervalType) {
		case EIntervalType.MONTH:
			return "month";
		case EIntervalType.YEAR:
			return "year";
		case EIntervalType.WEEK:
			return "week";
		case EIntervalType.DAY:
			return "day";
		default:
			return "";
	}
};
