import { EEndpointType } from "amp";
import { useCallback } from "react";
import { useApi } from "src/hooks/useApi";
import Stripe from "stripe";
import { useMembershipPurchaseContext } from "../contexts/MembershipPurchaseContext";

interface ICreateVehicleSubscriptionRequest {
	accountId: string;
	modification: {
		closest_location_external_id: string;
		paymentMethodId: string;
		promoCodeId?: string;
		referralCode?: string;
		vehicle: {
			id: number;
			priceId: number;
		};
	};
}

interface ICreateUserSubscriptionRequest {
	accountId: string;
	modification: {
		closest_location_external_id: string;
		paymentMethodId: string;
		priceId: number;
		promoCodeId?: string;
		referralCode?: string;
	};
}

export const useCreateSubscription = () => {
	const { post } = useApi(EEndpointType.Anonymous, "account");
	const {
		kiosk: { location_id },
	} = useMembershipPurchaseContext();

	const createVehicleSubscription = useCallback(
		async (
			accountId: string,
			vehicleId: number,
			priceId: number,
			paymentMethodId: string,
			promoCodeId?: string,
			referralCode?: string,
		) => {
			await post<ICreateVehicleSubscriptionRequest, Stripe.Subscription>(
				"create-vehicle-subscription",
				{
					accountId,
					modification: {
						closest_location_external_id: location_id,
						paymentMethodId,
						promoCodeId,
						referralCode,
						vehicle: {
							id: vehicleId,
							priceId,
						},
					},
				},
			);
		},
		[post, location_id],
	);
	const createUserSubscription = useCallback(
		async (
			accountId: string,
			priceId: number,
			paymentMethodId: string,
			promoCodeId?: string,
			referralCode?: string,
		) => {
			await post<ICreateUserSubscriptionRequest, Stripe.Subscription>(
				"create-user-subscription",
				{
					accountId,
					modification: {
						closest_location_external_id: location_id,
						paymentMethodId,
						priceId,
						promoCodeId,
						referralCode,
					},
				},
			);
		},
		[post, location_id],
	);
	return { createVehicleSubscription, createUserSubscription };
};
