/* eslint-disable react-hooks/exhaustive-deps */
import {
	DynamicSql,
	EAccountApproach,
	EPriceType,
	IKiosk,
	IProductPriceWithJson,
	IProductWithJson,
} from "amp";
import {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useParams } from "react-router-dom";
import { IsLoading } from "../../../components/loading/IsLoading";
import { useFetchDataDynamic } from "../../../hooks/useFetchDataDynamic";
import { useGetAccountApproach } from "../../../hooks/useGetAccountApproach";
import { initializeContext } from "../../../utils/initialize-context";
import { useGetNonsubscribedUserByPhoneNumber } from "../hooks/useGetPhoneNumberExists";
import { AxiosError } from "axios";
import { useTenantRouter } from "src/hooks/useTenantRouter";
import { getAmpApiError } from "src/utils/getAmpApiError";
import { useModalContext } from "src/contexts/ModalContext";

export enum EMembershipPurchaseScreen {
	PriceLevel,
	Info,
	Payment,
	Success,
}

export interface IUserData {
	userId: string;
	accountId: string;
	stripeCustomerId: string;
	phoneNumber?: string;
	email?: string;
	name?: string;
	vehicle?: {
		id: number;
		licensePlateNumber: string;
		licensePlateState: string;
	};
}

interface IMembershipPurchaseContext {
	loading: boolean;
	accountApproach: EAccountApproach;
	kiosk: IKiosk;
	initialCustomerPhoneNumber: string;
	product: IProductWithJson | undefined;
	prices: IProductPriceWithJson[] | undefined;
	selectedPriceId: number | undefined;
	setSelectedPriceId: (priceId: number) => void;
	userData: IUserData | undefined;
	setUserData: (userData: IUserData) => void;
	screen: EMembershipPurchaseScreen;
	setScreen: (screen: EMembershipPurchaseScreen) => void;
}

export const [
	MembershipPurchaseContext,
	useMembershipPurchaseContext,
	MembershipPurchaseContextProvider,
] = initializeContext<IMembershipPurchaseContext>("Membership Purchase");

export const MembershipPurchaseProvider: FC<PropsWithChildren> = ({
	children,
}): JSX.Element => {
	// States
	const [loadingContext, setLoadingContext] = useState(true);
	const [userData, setUserData] = useState<IUserData | undefined>();
	const [selectedPriceId, setSelectedPriceId] = useState<number | undefined>();
	const [screen, setScreen] = useState<EMembershipPurchaseScreen>(
		EMembershipPurchaseScreen.PriceLevel,
	);
	// HOOKS
	const navigate = useTenantRouter();
	// Get params
	const {
		kioskId,
		productId,
		phoneNumber: initialCustomerPhoneNumber,
	} = useParams() as {
		kioskId: string;
		productId: string;
		phoneNumber: string;
	};
	// Get account approach
	const { accountApproach, loading: loadingAccountApproach } =
		useGetAccountApproach();
	// Get nonsubscribed user
	const getNonsubscribedUser = useGetNonsubscribedUserByPhoneNumber();

	const getAmpUserIfExists = useCallback(
		async (phoneNumber: string) => {
			try {
				const userData = await getNonsubscribedUser(phoneNumber);
				if (userData) {
					setUserData({
						userId: userData.userId,
						accountId: userData.accountId,
						stripeCustomerId: userData.stripeCustomerId,
						email: userData.email ?? undefined,
						name: userData.name ?? undefined,
					});
				}
			} catch (error: unknown) {
				const ampError = getAmpApiError(error);
				if (ampError) {
					navigate.push(`/membership-purchase/unauthorized/${ampError.message}`);
				} else {
					navigate.push(`/membership-purchase/unauthorized/Unable to complete signup process with the provided phone number`);
				}
			}
		},
		[getNonsubscribedUser],
	);

	useEffect(() => {
		getAmpUserIfExists(initialCustomerPhoneNumber);
	}, [initialCustomerPhoneNumber]);
	// Fetch product
	const { data: products, loading: loadingProduct } = useFetchDataDynamic(
		new DynamicSql("products", (product) =>
			product
				.addCondition(product.eq("id", parseInt(productId)))
				.addCondition(product.eq("active", true))
				.addJoin("prices", (prices) =>
					prices.addCondition(prices.eq("active", true)),
				),
		),
	);
	// Fetch kiosk
	const { data: kiosks, loading: loadingKiosk } = useFetchDataDynamic(
		new DynamicSql("kiosks", (kiosk) =>
			kiosk
				.addCondition(kiosk.eq("id", parseInt(kioskId)))
				.addJoin("kioskLocation"),
		),
	);
	// Create kiosk
	const kiosk = useMemo(() => {
		if (!kiosks) return;
		return kiosks[0];
	}, [kiosks]);
	// Create product
	const product = useMemo(() => {
		if (!products) return;
		return products[0];
	}, [products]);
	// Create prices
	const prices = useMemo(() => {
		if (!product?.prices) return;
		return product.prices.filter(
			(price) =>
				price.metadata.Type === EPriceType.Standard &&
				price.metadata.PriceGroupId ===
					kiosk?.kioskLocation?.price_group_id__c &&
				price.active &&
				price.parent_product_price_id === null,
		);
	}, [product, kiosk]);

	useEffect(() => {
		if (prices) {
			if (prices?.length === 1) {
				setSelectedPriceId(prices[0].id);
				setScreen(EMembershipPurchaseScreen.Info);
			}
			setLoadingContext(false);
		}
	}, [prices]);
	// Show loading
	if (
		!accountApproach ||
		!kiosk ||
		!productId ||
		!initialCustomerPhoneNumber ||
		loadingContext
	) {
		return <IsLoading />;
	}
	// Return context provider
	return (
		<MembershipPurchaseContextProvider
			value={{
				loading: loadingAccountApproach || loadingProduct || loadingKiosk,
				accountApproach,
				kiosk: kiosk,
				initialCustomerPhoneNumber,
				product,
				prices,
				selectedPriceId,
				setSelectedPriceId,
				userData,
				setUserData,
				screen,
				setScreen,
			}}
		>
			{children}
		</MembershipPurchaseContextProvider>
	);
};
