import React, { Container, Modal } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ReactNode } from "react";
import styles from "./amp-modal.module.scss";
import CloseIcon from "@material-ui/icons/Close";
export interface IModalProps {
	visible: boolean;
	children: ReactNode;
	modalStyles?: CSSProperties;
	onClose: () => void;
	showCloseIcon?: boolean;
}
export const AmpModal = ({
	visible,
	children,
	onClose,
	modalStyles,
	showCloseIcon = true,
}: IModalProps) => {
	const { modal, content } = styles;
	return (
		<Modal open={visible} className={modal} onClose={onClose}>
			<Container className={content} style={modalStyles}>
				{showCloseIcon && (
					<CloseIcon
						onClick={onClose}
						style={{
							position: "absolute",
							right: 20,
							top: 20,
							cursor: "pointer",
						}}
					/>
				)}
				<>{children}</>
			</Container>
		</Modal>
	);
};
