import { DynamicSql } from "amp";
import { useFetchDataDynamic } from "./useFetchDataDynamic";

export const useGetOneLinkUrl = () => {
	const { data: generalInformation, loading } = useFetchDataDynamic(
		new DynamicSql("generalInformation").setColumns([
			"onelink_url",
		]),
	);
    const oneLinkUrl = generalInformation?.[0]?.onelink_url;
	return {
		oneLinkUrl,
		loading,
	};
};
