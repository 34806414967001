import { useCallback, useEffect, useState } from "react";
import { AmpBottomSheet } from "src/components/amp-bottom-sheet/AmpBottomSheet";
import { AmpButton } from "src/components/ui-core/buttons/AmpButton";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpTextInput } from "src/components/ui-core/inputs/AmpTextInput";
import { useValidateReferralCode } from "../hooks/useValidateReferralCode";

export interface IPromoCodeModalProps {
	isOpen: boolean;
	onClose: () => void;
	onValidReferallCodeSubmitted: (code: string) => void;
}

export const ReferralCodeModal = ({
	isOpen,
	onClose,
	onValidReferallCodeSubmitted,
}: IPromoCodeModalProps) => {
	const [referralCode, setReferralCode] = useState<string>("");
	const [error, setError] = useState<string>("");

	const onModalClose = useCallback(() => {
		onClose();
		setReferralCode("");
		setError("");
	}, [onClose]);

	const validateReferralCode = useValidateReferralCode();

	const triggerValidation = useCallback(async () => {
		const response = await validateReferralCode(referralCode);
		if (response.valid) {
			onValidReferallCodeSubmitted(referralCode);
			onModalClose();
		} else {
			setError("The referral code is invalid. Please try a different code.");
		}
	}, [
		referralCode,
		validateReferralCode,
		onValidReferallCodeSubmitted,
		onModalClose,
	]);

	useEffect(() => {
		if (Boolean(error) && referralCode.length === 0) {
			setError("");
		}
	}, [referralCode, setError, error]);

	return (
		<AmpBottomSheet isOpen={isOpen} onClose={onModalClose}>
			<AmpContainer width="full" padding={4} gap={4}>
				<AmpTextInput
					fullWidth
					size="small"
					variant="outlined"
					name=""
					type="text"
					label="Referral Code"
					value={referralCode}
					onChange={(e) => {
						setReferralCode(e.target.value.toUpperCase());
					}}
					error={Boolean(error)}
					helperText={Boolean(error) && error}
				/>
				<AmpButton
					label="Submit"
					onClick={triggerValidation}
					disabled={referralCode.length === 0}
					colorType="secondary"
					width="wide"
				/>
			</AmpContainer>
		</AmpBottomSheet>
	);
};
