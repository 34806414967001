import { Grid, LinearProgress } from "@material-ui/core";
import { AmpCard } from "src/components/ui-core/containers/AmpCard";
import { AmpContainer } from "src/components/ui-core/containers/AmpContainer";
import { AmpText } from "src/components/ui-core/typography/AmpText";
import { useFetchData } from "src/hooks/useFetchData";
import { EMembershipPurchaseScreen, useMembershipPurchaseContext } from "../../contexts/MembershipPurchaseContext";
import { useMembershipSignupPayments } from "../../hooks/useMembershipSignupPayments";
import { ProductPaymentScreen } from "./components/ProductPaymentScreen";

export const PurchaseProductScreen = () => {
	const { userData, selectedPriceId, setScreen } = useMembershipPurchaseContext();
	const { getSetupIntentClientSecret } = useMembershipSignupPayments();

	const { data: clientSecret, loading: loadingClientSecret } = useFetchData(
		() => {
			if (!userData) return Promise.reject("No user data");
			return getSetupIntentClientSecret(userData.stripeCustomerId);
		},
	);

	if (loadingClientSecret || !clientSecret) {
		return (
			<AmpContainer width="full" height="full">
				<AmpContainer
					padding={4}
					direction="column"
					alignItems="center"
					width="full"
					color="surface"
					flexGrow={1}
				>
					<Grid container item direction="column" spacing={3} md={6} sm={12}>
						<Grid item>
							<AmpCard padding={6} gap={6} flexDirection="column">
								{loadingClientSecret ? (
									<LinearProgress />
								) : (
									<AmpText type="body_lg">
										Error loading account information. Please try again.
									</AmpText>
								)}
							</AmpCard>
						</Grid>
					</Grid>
				</AmpContainer>
			</AmpContainer>
		);
	}

	if (!selectedPriceId) {
		return null;
	}

	return (
		<ProductPaymentScreen
			priceId={selectedPriceId}
			setupIntentClientSecret={clientSecret}
			onGoBack={() => setScreen(EMembershipPurchaseScreen.Info)}
		/>
	);
};